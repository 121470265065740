<template lang='pug'>
	.about.constrainer
		.first-content
			Crumbs(slug='/privacy-policy')
			h1.title Cookie & Privacy Policy.
			.dashes
				.dash(v-for='n in 5')
			div(
				v-for="item in items"
				:key="item.id"
			)
				p.para.fs.bold {{ item.title }}
				p.para.fs(
					v-html="item.text.replaceAll('\\n', '<br>')"
				)
				Contact
</template>

<script>
// @ is an alias to /src
export default {
	name: 'About',
	metaInfo: {
		title: 'Safe.is - Cookie & Privacy Policy.',
		meta: [
			{
				name: 'og:site_name',
				property: 'og:site_name',
				content: 'Safe.is'
			},
			{
				name: 'og:url',
				property: 'og:url',
				content: 'https://safe.is/privacy-policy/index.html'
			},
			{
				name: 'og:image',
				property: 'og:image',
				content: 'https://safe.is/interesting/spring.jpg'
			},
			{
				name: 'og:title',
				property: 'og:title',
				content: 'Safe.is - Cookie & Privacy Policy.'
			},
			{
				name: 'og:description',
				property: 'og:description',
				content: 'What do our cookies do?'
			},
			{
				name: 'description',
				property: 'description',
				content: 'What do our cookies do?'
			}
		]
	},
	data: function () {
		return {
			items: []
		};
	},
	created: async function () {
		this.axios.get('https://us-central1-safe-3ee55.cloudfunctions.net/privacyPolicy').then((result) => {
			this.items = result.data;
		});
	},
	mounted: function () {
		this.$store.state.keydex += 1;
	}
};
</script>

<style lang='less'>
.about {
	margin-top: 20px;
	margin-bottom: 20px;
	.dashes {
		margin-top: 28px;
		margin-bottom: 37px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		width: 60px;
		.dash {
			width: 8px;
			height: 2px;
			background-color: #F9DB49;
		}
	}
	.first-content {
		margin-bottom: 100px;
		.para {
			max-width: 764px;
			&.bold {
				font-weight: bold;
			}
		}
	}
}
</style>
